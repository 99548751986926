/*
 * Navigation
 */
app.add('navigation', {

    bp: 768,

    dom: {
        header: document.querySelector('#header .container'),
        mobile: document.querySelector('.header-mobile'),
        menu:   document.querySelector('#header .navigation')
    },

    onResize: function(viewport){
        if (viewport.width > this.bp && this.dom.menu.parentNode != this.dom.header) {
            this.dom.header.appendChild(this.dom.menu);
        }
     
        if (viewport.width <= this.bp && this.dom.menu.parentNode != this.dom.mobile) {
            this.dom.mobile.appendChild(this.dom.menu);
        }
    }
});

