/*
 * Viewport
 */
app.add('viewport', {

    init: function (app) {
        window.addEventListener('resize', this);
        this.refresh();
    },

    //hooked to window.resize
    handleEvent: function(){
        this.refresh();
    },

    refresh: function(){
        this.width = window.innerWidth   || document.documentElement.clientWidth  || document.body.clientWidth;
        this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        app.notify('Resize', this);
    }
});
