/*
 * Load images with JavaScript too.
 */
app.add('loader-image', {

    onAppReady: function(){
        var nodes = document.querySelectorAll('img, [data-background]');
        var i = nodes.length;
        while (i--){
            this.load(nodes[i]);
        }
    },

    load: function(node){
        var url = node.getAttribute('data-src') || node.getAttribute('data-background');
        if (url) {
            app.notify('AssetAdded', node);
            url = app.screen.highDensity ? url.replace('@1x', '@2x') : url;

            var img = node.getAttribute('data-src') ? node : document.createElement('img');
            img.addEventListener('load', this.notifyOfAssetLoaded);
            img.addEventListener('error', this.notifyOfAssetLoaded);
            img.src = url;

            if (!node.getAttribute('data-src')) {
                node.style['background-image'] = 'url('+url+')';
            }
        }
    },

    notifyOfAssetLoaded: function(){
        app.notify('AssetLoaded', this);
    }

});

