/*
 * Our Application
 */
var app = {

    listeners: {},

    add: function (name, module) {
        this[name] = module;
        if (module.init) {
            module.init(this);
        }
        for (var method in module){
            if (/^on[A-Z]/.test(method)) {
                this.listeners[method] = this.listeners[method] || [];
                this.listeners[method].push(module);
            }
        }
    },

    notify: function(event){
        var method = 'on'+event;
        var listeners = this.listeners[method];
        var i = listeners ? listeners.length : 0;
        var args = [].slice.call(arguments, 1);
        while (i--){
            listeners[i][method].apply(listeners[i], args);
        }
    },
    
    run: function(){
        app.notify('AppReady');
    }
};

