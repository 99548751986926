/*
 * Load web fonts with https://github.com/typekit/webfontloader
 */
app.add('loader-font', {
    
    onAppReady: function(){
        app.notify('AssetAdded', 'fonts');
        if (window.WebFont) {
            this.loadFonts();
        } else {
            document.getElementById('web-font-loader').addEventListener('load', this.loadFonts);
        }
    },

    loadFonts: function(){
        var loaded = function(){
            app.notify('AssetLoaded', 'fonts');
        }

        window.WebFont.load({
            classes: false,
            active: loaded,
            custom: {
                families: 'Gotham-Medium, Gotham-Book, Perpetua, Perpetua-Italic, icomoon'.split(', '),
                testStrings: {
                  icomoon: '\uE901\uE900'
                }
            },
        });
    },
});

