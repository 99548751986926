/*
 * Load stylesheets with JavaScript too.
 */
app.add('loader', {
    
    onAppReady: function(){
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = "/style/style.css?0.0.4";
        link.addEventListener('load', this.notifyOfAssetLoaded);
        link.addEventListener('error', this.notifyOfAssetLoaded);
        document.head.appendChild(link);
        app.notify('AssetAdded', 'stylesheet');
    },

    notifyOfAssetLoaded: function(){
        app.notify('AssetLoaded', 'stylesheet');
    }
    
});

