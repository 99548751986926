/*
 * Loading progress
 */
app.add('loader', {

    assets: {
        total: 0,
        loaded: 0
    },

    onAppReady: function(){
        this.view = {
            foreground: document.getElementById('foreground')
        }
    },

    onAssetAdded: function(asset){
        this.assets.total++;
        this.resetTimeout();
    },

    onAssetLoaded: function(asset){
        this.assets.loaded++;
        this.resetTimeout();
        var ratio = this.assets.loaded / this.assets.total;
        this.view.foreground.style.width = 100*ratio + "%";
        if (ratio == 1) {
            this.complete();
        }
    },

    //this is fallback in case something does not load
    resetTimeout: function(){
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.complete, 15000);
    },

    complete: function(){
        clearTimeout(this.timeout);
        app.notify('AllLoaded');
        document.body.className += ' all-loaded';
        app.loader.complete = function(){};
    }
});

