/*
 * Screen
 */
app.add('screen', {
    init: function() {
        if (window.matchMedia) {
            var mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
            this.highDensity = (mq && mq.matches || (window.devicePixelRatio > 1)); 
        }
    }
});

